import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text } from '../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="পরিচিতি" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  মাদ্‌রাসাতুল ইল্‌ম এর সংক্ষিপ্ত পরিচিতি
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/about/',
        name: 'পরিচিতি',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <p>{`আল-কুর্‌আনুল কারীমের হিফয কার্যক্রমকে কেন্দ্র করে বাংলাদেশে গড়ে উঠেছে অসংখ্য প্রতিষ্ঠান। কিন্তু আমরা লক্ষ্য করেছি, প্রতিষ্ঠানগুলোর বেশিরভাগই আল-কুর্‌আনের মূল উদ্দেশ্যকে পূরণ করতে ব্যর্থ। পবিত্র গ্রন্থ আল-কুর্‌আন নাযিলের মূল উদ্দেশ্য সম্পর্কে আল্লাহ বলেন:`}</p>
      <Text textAlign="center" variant="h4" mdxType="Text">
  كِتَابٌ أَنْزَلْنَاهُ إِلَيْكَ مُبَارَكٌ لِيَدَّبَّرُوا آيَاتِهِ
  وَلِيَتَذَكَّرَ أُولُو الْأَلْبَابِ
      </Text>
      <p>{`“এক মুবারক কিতাব, এটা আমরা আপনার প্রতি নাযিল করেছি, যাতে মানুষ এর আয়াত সমূহ গভীরভাবে চিন্তা করে এবং যাতে বোধশক্তিসম্পন্ন ব্যক্তিরা গ্ৰহণ করে উপদেশ।” (সুরা 38 সাদ: ২৯)`}</p>
      <p>{`আর তাই, হিফযুল কুর্‌আনে এক বৈচিত্র আনার লক্ষ্য নিয়ে ‘মাদ্রাসাতুল ইলম’ যাত্রা শুরু করে ২০১৯ সালে। মাদ্রাসাতুল ইলম এর বৈশিষ্ট্যগুলো নিম্নরূপ:`}</p>
      <h3 {...{
        "id": "নূরানী-ও-নাযেরা-প্রোগ্রাম",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%A8%E0%A7%82%E0%A6%B0%E0%A6%BE%E0%A6%A8%E0%A7%80-%E0%A6%93-%E0%A6%A8%E0%A6%BE%E0%A6%AF%E0%A7%87%E0%A6%B0%E0%A6%BE-%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A7%8B%E0%A6%97%E0%A7%8D%E0%A6%B0%E0%A6%BE%E0%A6%AE",
          "aria-label": "নূরানী ও নাযেরা প্রোগ্রাম permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`নূরানী ও নাযেরা প্রোগ্রাম:`}</h3>
      <p>{`বই এর পাশাপাশি দেয়ালে টাঙানো TV Screen এ Powerpoint Presentation ও Video এর মাধ্যমে
শিক্ষা দেওয়া হয় আরবী পড়তে পারার যোগ্যতা, যাকে নূরানী শিক্ষা বলা হয়ে থাকে। এ বিষয়ক মাদ্রাসাতুল ইলম এর কিছু Powerpoint Presentation এই সাইট এর Study Materials থেকে ডাউনলোড করতে পারেন।
গোটা কুর্‌আন পড়তে পারার যোগ্যতাকে বলা হয় নাযেরা শিক্ষা। নূরানী ও নাযেরা এ দুই-ই পরিচালনা করেন মাদ্রাসাতুল ইলম এর অভিজ্ঞ হিফয উস্তাজ।
নূরানী ও নাযেরা শিক্ষারত অবস্থায় শিক্ষার্থীরা আবাসিক বা অনাবাসিক যেকোন সুবিধা গ্রহণ করতে পারেন।`}</p>
      <h3 {...{
        "id": "হিফয-প্রোগ্রাম",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%B9%E0%A6%BF%E0%A6%AB%E0%A6%AF-%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A7%8B%E0%A6%97%E0%A7%8D%E0%A6%B0%E0%A6%BE%E0%A6%AE",
          "aria-label": "হিফয প্রোগ্রাম permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`হিফয প্রোগ্রাম:`}</h3>
      <p>{`মাদ্রাসাতুল ইলম এর আবাসিক বোর্ডিং এ থেকে হিফযুল কুর্‌আন কোর্সে ভর্তি হতে হবে।`}</p>
      <h3 {...{
        "id": "সহীহ-তিলাওয়াত",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%B8%E0%A6%B9%E0%A7%80%E0%A6%B9-%E0%A6%A4%E0%A6%BF%E0%A6%B2%E0%A6%BE%E0%A6%93%E0%A7%9F%E0%A6%BE%E0%A6%A4",
          "aria-label": "সহীহ তিলাওয়াত permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`সহীহ তিলাওয়াত:`}</h3>
      <p>{`অনেক হাফিযকেই দেখা যায় কুর্‌আন ভালোভাবে হিফয করলেও তিলাওয়াত ত্রুটিপূর্ণ। আর তাই মাদ্রাসাতুল ইলম এই বিষয়ে বিশেষ নযর দেয়। নূরানী, নাযেরা ও হিফয কার্যক্রম চলমান অবস্থায় সহীহ তিলাওয়াতের জন্য অভিজ্ঞ উস্তাজ দ্বারা নিয়মিত মশক করানো হয়।
পাশাপাশি বাচ্চাদের TV Screen এ বিশ্ববিখ্যাত ক্বারী ‘আইমান সুয়াইদ’ এর তিলাওয়াত শুনিয়ে অনুশীলন করানো হয় নিয়মিত।`}</p>
      <h3 {...{
        "id": "আরবী-ভাষা-শিক্ষা",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%86%E0%A6%B0%E0%A6%AC%E0%A7%80-%E0%A6%AD%E0%A6%BE%E0%A6%B7%E0%A6%BE-%E0%A6%B6%E0%A6%BF%E0%A6%95%E0%A7%8D%E0%A6%B7%E0%A6%BE",
          "aria-label": "আরবী ভাষা শিক্ষা permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`আরবী ভাষা শিক্ষা:`}</h3>
      <p>{`মাদ্রাসাতুন ইলম এর অন্যতম আকর্ষণ আরবী ভাষা শিক্ষা দান করা। এক্ষেত্রে মাদ্রাসাতুন ইলম এর লক্ষ্য:`}</p>
      <ul>
        <li parentName="ul">{`একজন ত্বলিবুল ইলম যেনো আল-কুর্‌আন মোটামোটি বুঝে মুখস্থ করতে পারে।`}</li>
        <li parentName="ul">{`কুর্‌আন হিফয করার পর যেনো মানুষকে আল-কুর্‌আন দিয়ে নাসীহাহ (উপদেশ) দিতে পারে।`}</li>
        <li parentName="ul">{`পরবর্তী উচ্চশিক্ষায় যেনো মাদ্রাসাতুল ইলম এর ত্বলিবুল ইলম অনেকটা এগিয়ে থাকে।`}</li>
      </ul>
      <p>{`কুর্‌আন হিফয এর পাশাপাশি অভিজ্ঞ শিক্ষক দ্বারা এই কার্যক্রম পরিচালনা করা হয়ে থাকে। এক্ষেত্রে আধুনিক ভাষা কারিকুলাম ‘লিন-নাশিঈন’ পাঠ দান করা হয়ে থাকে।
তাছাড়াও ত্বলিবুল ইলমদের শিখানো হয় অনেক আরবী শব্দ। এক্ষেত্রে ব্যবহার করা হয় উন্নত বই ও Powerpoint Presentation। এ বিষয়ক মাদ্রাসাতুন ইলম এর কিছু Powerpoint Presentation এই সাইট এর Study Materials থেকে ডাউনলোড করতে পারেন।`}</p>
      <h3 {...{
        "id": "সাধারণ-শিক্ষা",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%B8%E0%A6%BE%E0%A6%A7%E0%A6%BE%E0%A6%B0%E0%A6%A3-%E0%A6%B6%E0%A6%BF%E0%A6%95%E0%A7%8D%E0%A6%B7%E0%A6%BE",
          "aria-label": "সাধারণ শিক্ষা permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`সাধারণ শিক্ষা:`}</h3>
      <p>{`কুর্‌আন হিফয শেষে কোনো ত্বলিবুল ইলম যেনো দ্বীনি বা সাধারণ শিক্ষার যে কোনো ক্ষেত্রে যোগদান করতে পারে, তাই হিফয কার্যক্রমের পাশাপাশি চলে এই সাধারণ শিক্ষা কার্যক্রম। মাদ্রাসাতুন ইলম এ ক্ষেত্রে জাতীয় এবতেদায়ী কারিকুলাম অনুসরণ করে থাকে। রয়েছে PSC পরিক্ষায় অংশগ্রহণের সুযোগ। অভিজ্ঞ শিক্ষক দ্বারা এ কার্যক্রম পরিচালনা করা হয়ে থাকে।`}</p>
      <h3 {...{
        "id": "পার্ট-টাইম-মক্তব",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%AA%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%9F-%E0%A6%9F%E0%A6%BE%E0%A6%87%E0%A6%AE-%E0%A6%AE%E0%A6%95%E0%A7%8D%E0%A6%A4%E0%A6%AC",
          "aria-label": "পার্ট টাইম মক্তব permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`পার্ট টাইম মক্তব:`}</h3>
      <p>{`স্কুল পড়ুয়া ছাত্ররা মাদ্রাসাতুল ইলম এ এসে কুর্‌আন তিলাওয়াত ও আরবী ভাষা শিক্ষা করতে পারবে।`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      